@font-face {
  font-family: 'BerryRotunda';
  src:
    local('berry_rotunda-webfont'),
    url(../fonts/berry_rotunda-webfont.woff2) format('woff2'),
    local('berry_rotunda-webfont'),
    url(../fonts/berry_rotunda-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_proitalic';
  src:
    url('../fonts/sourcesanspro-italic-webfont.woff2') format('woff2'),
    url('../fonts/sourcesanspro-italic-webfont.woff') format('woff'),
    url('../fonts/sourcesanspro-italic-webfont.ttf') format('truetype'),
    url('../fonts/sourcesanspro-italic-webfont.svg#source_sans_proitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_prolight';
  src:
    url('../fonts/sourcesanspro-light-webfont.woff2') format('woff2'),
    url('../fonts/sourcesanspro-light-webfont.woff') format('woff'),
    url('../fonts/sourcesanspro-light-webfont.ttf') format('truetype'),
    url('../fonts/sourcesanspro-light-webfont.svg#source_sans_prolight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_proregular';
  src:
    url('../fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
    url('../fonts/sourcesanspro-regular-webfont.woff') format('woff'),
    url('../fonts/sourcesanspro-regular-webfont.ttf') format('truetype'),
    url('../fonts/sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_prosemibold';
  src:
    url('../fonts/sourcesanspro-semibold-webfont.woff2') format('woff2'),
    url('../fonts/sourcesanspro-semibold-webfont.woff') format('woff'),
    url('../fonts/sourcesanspro-semibold-webfont.ttf') format('truetype'),
    url('../fonts/sourcesanspro-semibold-webfont.svg#source_sans_prosemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
