/* Make tinyMCE images responsive*/
.img-responsive {
  max-width: 100%;
  height: auto;
}

.customSelect {
  margin: 0 5px;
}
.organogram_img {
  text-align: center;
}
.organogram_img img {
  margin: 0 auto;
}
.organoopen {
  cursor: pointer;
}
.dropdown_organogram {
  padding: 0.85rem 0.95rem !important;
}
.organoopen svg {
  font-size: 1rem;
}
.MuiModal-root > ::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.ReactModal__Content img {
  left: -8px;
}
.close_organogram {
  position: absolute;
  top: 50px;
  right: 50%;
  margin-right: -820px;
  cursor: pointer;
}

@media only screen and (max-width: 1800px) {
  .close_organogram {
    position: absolute;
    top: 50px;
    right: 50px;
    margin-right: 0px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1200px) {
  .close_organogram {
    position: absolute;
    top: 50px;
    right: 50%;
    margin-right: -150px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1155px) {
  .stepper-title {
    display: none !important;
  }
}
