.two-fa-container {
  display: flex;
  justify-content: space-between;
  max-width: 300px; /* Adjust as needed */
  margin: 0 auto; /* Center the container */
}

.two-fa-input {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s;
}

.two-fa-input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}
