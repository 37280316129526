// Used for theme swticher
.setting-color {
  padding: 0 5px;

  > label {
    display: block;
    position: relative;
    margin: 0 10px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    $baseHg: 15px;
    > .color {
      display: block;
      height: $baseHg * 1.2;
    }

    > .split {
      @include clearfix();
      display: block;
      > .color {
        display: block;
        height: $baseHg * 2.5;
        &:first-child {
          float: left;
          width: 70%;
        }
        &:last-child {
          float: right;
          width: 30%;
        }
      }
    } // icon checked
    > .icon-check {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -20px;
      margin-left: -10px;
      text-align: center;
      font-size: 1.33333333em;
      vertical-align: -15%;
      color: #fff;
      opacity: 0;
    }

    > input[type='radio'] {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      &:checked + .icon-check {
        opacity: 1 !important;
      }
    }
  }
}
form {
  .titles{
    &:first-child {
      legend{
        margin-top: 0 !important;
        border-top: none;
      }
    }
    legend{
      padding-top: 30px !important;
      border-top: .3px solid #ccc;
    }
    .buttons{
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
    }
  }
  .inputs{
    border: .3px solid #ccc;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: none;
    input{
      height: 80px;
      border-bottom: 1px solid #ccc;
          &:focus-visible{
            border-bottom: 2px solid #00f;;
          }
    }
  }
}
#allresign{
  position: relative;
  display: block;
  padding-top: 120px;
  z-index: 99;
  .alltext{
    h6{
      &:first-child{
        font-size: 25px;
        line-height: 35px;
        font-family: "Helvetica Neue",sans-serif;
        font-weight: 600;
        text-transform: uppercase;
      }
      &:nth-child(2){
        font-size: 20px;
          line-height: 30px;
          font-family: "Helvetica Neue", sans-serif;
          font-weight: 600 !important;
          margin-top: 20px !important;
          margin-bottom: 20px !important;
      }
      &:nth-child(3){
        font-size: 16px;
          line-height: 26px;
          font-family: "Helvetica Neue", sans-serif;
          font-weight: 500 !important;
          text-align: justify;
      }
    }
  }
  .firstImage {
      position: absolute;
      width: 100%;
      height: 380px;
      top: 0;
      left: 0;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
}

