.manageWagesTable {
  .MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        padding: 0px 16px;
        padding-bottom: 30px;
        margin-bottom: 20px;
        width: fit-content;
      }
    }
  }
}
