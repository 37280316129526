.header2-text-label {
  width: 150px;
  display: inline-block;
}

#RBS-Scheduler-root .anticon {
  vertical-align: 0;
}

.ant-col:nth-child(1) {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .ant-col:nth-child(1) {
    margin-bottom: 0px;
  }
}

.whirl:before {
  z-index: 999 !important;
  background: #fff !important;
}
