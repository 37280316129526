.vacancy-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .vacancy-list-item {
    flex: 1 1;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-left: 8px;

    &:nth-child(even) {
      background-color: #e2e2e2;
    }

    .vacancy-description {
      display: flex;
      flex: 0.6;
    }

    .vacancy-days {
      display: flex;
      flex: 0.2;
    }

    .vacancy-action {
      display: flex;
      flex: 0.2;
      justify-content: flex-end;
    }
  }
}

.btn-outline-warning {
  &:focus {
    color: #ff902b !important;
    box-shadow: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .mobilemargin {
    margin-bottom: 0 !important;
  }
  .button_addtask {
    padding: 15px;
    text-align: right;
    .btn {
      margin: 0 auto;
      float: none !important;
    }
  }
}
