.wrapper {
  background-color: #ffffff;
}

.main-container {
  margin-top: 220px;
}

.left-container {
  margin-top: 80px;
  float: left;
  vertical-align: middle;
  margin-left: 55px;
  padding-right: 0px !important;
}

.left-container img {
  width: 65%;
  margin-top: 17px;
}

.right-container {
  float: right;
  margin-left: 30px;
  margin-top: 18px;
}

.divider {
  height: 300px;
  width: 4px;
  background: rgb(167, 167, 167);
  background: linear-gradient(
    0deg,
    rgba(167, 167, 167, 0) 0%,
    rgba(101, 101, 101, 1) 12%,
    rgba(101, 101, 101, 1) 50%,
    rgba(101, 101, 101, 1) 80%,
    rgba(167, 167, 167, 0) 100%
  );
  margin-left: -137px;
}

@media screen and (max-width: 1600px) {
  .divider {
    margin-left: -117px;
  }
}

@media screen and (max-width: 1360px) {
  .divider {
    margin-left: -107px;
  }
}

@media screen and (max-width: 1200px) {
  .divider {
    margin-left: -97px;
  }
}

@media screen and (max-width: 1024px) {
  .main-container {
    margin-top: 500px;
  }

  .divider {
    margin-left: -75px;
  }
}

@media screen and (max-width: 991px) {
  .divider {
    display: none;
  }

  .main-container {
    margin-top: 85px;
  }

  .left-container {
    margin-left: 0px;
  }

  .right-container {
    margin-left: 0px;
  }

  .left-container img {
    width: 80%;
  }

  form {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .left-container {
    margin-left: 10px;
    margin-right: 10px;
  }

  .right-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 320px) {
  .main-container {
    margin-top: 55px;
  }
}

@media screen and (max-width: 823px) {
  .main-container {
    margin-top: 150px;
  }
}

@media screen and (max-height: 1024px) {
  .main-container {
    margin-top: 260px;
  }
}

@media screen and (max-height: 823px) {
  .main-container {
    margin-top: 170px;
  }
}

@media screen and (max-height: 816px) {
  .main-container {
    margin-top: 230px;
  }
}

@media screen and (max-height: 736px) {
  .main-container {
    margin-top: 140px;
  }
}

@media screen and (max-height: 731px) {
  .main-container {
    margin-top: 120px;
  }
}

@media screen and (max-height: 667px) {
  .main-container {
    margin-top: 110px;
  }
}

@media screen and (max-height: 640px) {
  .main-container {
    margin-top: 90px;
  }
}

@media screen and (max-height: 568px) {
  .main-container {
    margin-top: 60px;
  }
}
