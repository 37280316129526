/* ========================================================================
     Component: cards
 ========================================================================== */

.card {
  margin-bottom: 1.25rem; //20px
}

//
// Adds support to use more components inside cards
//
.card {
  .table {
    margin-bottom: 0;
    > thead > tr > th {
      border-top: 0;
    }
  }
  &.card-transparent {
    border: 0;
    background-color: transparent;
    box-shadow: 0 0 0 #000;
    .card-header,
    .card-body {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.card-flat {
  margin: 0 !important;
  border: 0;
}

.card-columns-2 {
  column-count: 1;
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
}

.card-tool {
  display: inline-block;
  color: #fff;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  > em + em {
    margin-left: 12px;
  }
}

.card-header {
  .card-default & {
    > .card-tool {
      color: #c1c2c3;
    }
  } // right floated labels adjust position
  > .badge.float-right {
    margin-top: 3px;
    + .badge.float-right {
      margin-right: 10px;
    }
  }
}

.card-post {
  margin-right: -10px;
}

.btn-right {
  padding-left: 0px;
}

.btn-left {
  padding-right: 7px;
}

.card-footer {
  .pagination {
    margin: 0;
  }
  .radial-bar {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
}

.data-description {
  width: 88%;
}

.col-lg-12 {
  padding-left: 9px !important;
}

@media only screen and (max-width: 1200px) {
  .nowrapmobile {
    flex-wrap: nowrap !important;
  }
}
@media only screen and (max-width: 516px) {
  .name-badge {
    position: absolute;
    top: 50px;
    width: 200px;
    right: -92px;
    left: auto;
  }
}

@media only screen and (max-width: 414px) {
  .col-lg-12 {
    padding-left: 4px !important;
  }

  .btn-left {
    padding-left: 6px;
  }
}

@media only screen and (max-width: 375px) {
  .col-lg-12 {
    padding-right: 6px !important;
    padding-left: 0px !important;
  }

  .btn-left {
    padding-right: 3px;
    padding-left: 7px;
  }
}

@media only screen and (max-width: 320px) {
  .btn-left {
    padding-right: 8px;
    padding-left: 0px;
    margin-left: -3px;
  }

  .btn-right {
    padding-left: 9px;
  }
}
